<template>
  <div class="page-item-wrap">
    <main class="sub __event">
      <div class="inner __event-wrap">
        <div class="tit">
          <h1>신청하기</h1>
          <p class="stit">속초/여수 한 달 살기 이벤트 신청하기</p>
        </div>
        <div class="sub-cont">
          <h2 class="h2-join">신청자 정보</h2>
          <div class="form-wrap">
            <div class="input-form different-input-form">
              <div class="t-head" style="width: 132px">
                신청지역<i class="required"></i>
              </div>
              <div class="checkCon">
                <input
                  type="radio"
                  class="text"
                  id="sokcho"
                  name="region"
                  v-model="region"
                  ref="region"
                  value="sokcho"
                />
                <label for="sokcho">속초</label>
                <input
                  type="radio"
                  class="text"
                  id="yeosu"
                  name="region"
                  v-model="region"
                  ref="region"
                  value="yeosu"
                />
                <label for="yeosu">여수</label>
              </div>
            </div>
            <div class="input-form different-input-form">
              <div class="t-head" style="width: 132px">
                신청차수<i class="required"></i>
              </div>
              <div id="orderCheckCon" class="checkCon">
                <div>
                  <input
                    type="radio"
                    class="text"
                    id="order_full"
                    name="period"
                    v-model="period"
                    ref="period"
                    value="order_full"
                    disabled="ture"
                  />
                  <label
                    for="order_full"
                    @click="endEvent"
                    v-html="order_fullText"
                  ></label>
                </div>
                <div>
                  <input
                    type="radio"
                    class="text"
                    id="order_1"
                    name="period"
                    v-model="period"
                    ref="period"
                    value="order_1"
                    disabled="ture"
                  />
                  <label
                    for="order_1"
                    @click="endEvent"
                    v-html="order_1text"
                  ></label>
                </div>
                <div>
                  <input
                    type="radio"
                    class="text"
                    id="order_2"
                    name="period"
                    v-model="period"
                    ref="period"
                    value="order_2"
                    checked
                  />
                  <label for="order_2" v-html="order_2text"></label>
                </div>
              </div>
            </div>
            <div class="input-form">
              <div class="t-head">이름<i class="required"></i></div>
              <input type="text" class="text" ref="userNm" v-model="userNm" />
            </div>
            <div class="input-form mobile-different-form">
              <div class="t-head">성별<i class="required"></i></div>
              <div class="rightForm">
                <li
                  v-for="(genderItem, idx) in OPTION_GENDER"
                  :key="genderItem.name"
                >
                  <input
                    v-model="gender"
                    type="radio"
                    class="checkbox"
                    :ref="'genderCode_' + idx"
                    :id="genderItem.value"
                    :value="genderItem.value"
                    name="gender"
                  />
                  <label :for="genderItem.value">{{ genderItem.name }}</label>
                </li>
              </div>
            </div>
            <div class="input-form">
              <div class="t-head">연락처<i class="required"></i></div>
              <input type="number" class="text" ref="phone" v-model="phone" />
            </div>
            <div class="input-form divisionForm">
              <div class="t-head">비상연락망<i class="required"></i></div>
              <input
                type="text"
                class="text"
                ref="emergencyContact"
                v-model="emergencyContact"
              />
              <div class="t-head t-sub" style="text-align: right">
                관계<i class="required" style="padding-right: 10px"></i>
              </div>
              <input
                type="text"
                class="text subtext"
                ref="relation"
                v-model="relation"
              />
            </div>
            <div class="input-form">
              <div class="t-head">생년월일<i class="required"></i></div>
              <input
                type="text"
                class="text"
                ref="birthday"
                v-model="birthday"
                placeholder="ex) 19990101"
              />
            </div>
            <div class="input-form">
              <div class="t-head">이메일<i class="required"></i></div>
              <input type="text" class="text" ref="email" v-model="email" />
            </div>
            <div
              class="input-form different-input-form mobile-different-form"
              style="display: flex"
            >
              <div class="t-head" style="width: 132px">
                회원 사진<i class="required"></i>
              </div>
              <input
                type="text"
                class="inp addr1 file-value text"
                v-model="photoFileName"
                readonly
              />
              <input
                type="file"
                ref="fileInput"
                accept="image/*"
                class="hidden"
                @change="onFileChanged"
              />
              <button
                type="button"
                id="btn-file-upload"
                class="btn-func"
                ref="photoFileName"
                @click="onUploadClick"
              >
                사진업로드
              </button>
              <div class="profile-item-wrap">
                <div
                  class="pic"
                  style="border: 1px solid #ddd"
                  :style="userImg"
                ></div>
                <span class="photoSpan"
                  >본인의 증명사진을 업로드해주세요.<br />업로드된 사진은
                  담당자에게 공개되며<br />실제 본인의 사진이 아닐 경우<br />
                  불이익이 발생할 수 있습니다.</span
                >
              </div>
            </div>
            <div class="input-form" @click="findAddress">
              <div class="t-head" style="width: 132px">
                주소<i class="required"></i>
              </div>
              <input
                type="text"
                v-model="address"
                class="inp addr1 text"
                ref="address"
                readonly
              />
              <button type="button" class="btn-func">주소찾기</button>
            </div>
            <div class="input-form">
              <div class="t-head">나머지 주소<i class="required"></i></div>
              <input
                type="text"
                class="text"
                v-model="addressDetail"
                ref="addressDetail"
              />
            </div>
            <div class="input-form divisionForm">
              <div class="t-head">학교명<i class="required"></i></div>
              <input
                type="text"
                class="text"
                ref="schoolName"
                v-model="schoolName"
              />
              <div class="t-head t-sub" style="text-align: right">
                학년<i class="required" style="padding-right: 10px"></i>
              </div>
              <input
                type="text"
                class="text subtext"
                ref="schoolGrade"
                v-model="schoolGrade"
              />
            </div>
            <div class="input-form">
              <div class="t-head">
                건강 및 특이사항
                <i class="required"></i>
              </div>
              <input
                type="text"
                class="text"
                ref="uniqueness"
                v-model="uniqueness"
                placeholder="기저질환 및 복용하는 약이 있다면 작성해주세요."
              />
              <!-- <span>없으면 없음이라 작성해주세요.</span> -->
            </div>
            <div class="input-form different-input-form">
              <div class="t-head" style="width: 132px">
                SNS<i class="required"></i>
              </div>
              <div class="checkCon">
                <input
                  type="checkbox"
                  class="text"
                  id="instagram"
                  v-model="instagram"
                  true-value="Y"
                  false-value="N"
                />
                <label for="instagram">인스타그램</label>
                <input
                  type="checkbox"
                  class="text"
                  id="facebook"
                  v-model="facebook"
                  true-value="Y"
                  false-value="N"
                />
                <label for="facebook">페이스북</label>
                <input
                  type="checkbox"
                  class="text"
                  id="blog"
                  v-model="blog"
                  true-value="Y"
                  false-value="N"
                />
                <label for="blog">블로그</label>
                <input
                  type="checkbox"
                  class="text"
                  id="youtube"
                  v-model="youtube"
                  true-value="Y"
                  false-value="N"
                />
                <label for="youtube">유튜브</label>
              </div>
              <span>인원선발에 가산점이 되는 항목입니다.</span>
            </div>
            <div class="input-form different-input-form">
              <div class="t-head" style="width: 132px">
                경력<i class="required"></i>
              </div>
              <div class="checkCon">
                <input
                  type="radio"
                  class="text"
                  id="Newcomer"
                  name="arebeitYn"
                  v-model="arebeitYn"
                  value="N"
                  checked
                />
                <label for="Newcomer">1년 이하</label>
                <input
                  type="radio"
                  class="text"
                  id="experienced"
                  name="arebeitYn"
                  v-model="arebeitYn"
                  value="Y"
                />
                <label for="experienced">1년 이상</label>
              </div>
              <span>인원선발에 가산점이 되는 항목입니다.</span>
            </div>
            <div
              class="input-form different-input-form mobile-different-form"
              style="align-items: start"
            >
              <div class="t-head" style="width: 132px">
                지원동기<i class="required"></i>
              </div>
              <textarea
                v-model="motive"
                ref="motive"
                name="motive"
                id="motive"
                cols="30"
                rows="10"
              ></textarea>
              <p class="textarea-info">
                <span style="color: red; font-size: 14px; float: left"
                  >이모티콘은 사용하실 수 없습니다.</span
                >({{ wordCount }}
                자)
              </p>
            </div>
            <div class="check-box type01">
              <p class="check-wrap" :class="{ on: isOpenShow }">
                <input
                  type="checkbox"
                  id="c1-2"
                  v-model="privacyYn"
                  ref="checkOpen"
                  true-value="Y"
                  false-value="N"
                />
                <label
                  >개인 정보보호 정책
                  <span class="required"></span>
                </label>
                <i class="el-icon-arrow-down" @click="toggleOpen"></i>
              </p>
              <div class="agree-item-wrap" v-html="jejuEventTerms"></div>
            </div>
            <button class="btn" v-if="!isApplicationForm" @click="save">
              신청서 제출
            </button>
            <span class="saveCompletion" v-if="isApplicationForm">
              신청서 제출이 완료되었습니다. 합격자 발표일 : 7월21일(개별 통보)
            </span>
            <!-- <button class="btn" v-if="isApplicationForm" @click="snsPoint">
              당첨확률 높이는 방법 확인하기
            </button> -->
          </div>
        </div>
      </div>
    </main>
    <jujeSnsPopup v-if="snsPoints" @jejuSns="snsData"></jujeSnsPopup>
  </div>
</template>

<script>
import { OPTION_GENDER } from 'Configs/registJob'
import { privacy, jejuEvent } from 'Configs/terms'
import { searchAddressGetResult } from 'Utils/message'
import jujeSnsPopup from 'Components/application/partials/jujeSnsPopup'
import {
  uploadUserImg,
  getBasicUserInfo,
  getEventRInfo,
  putEventRInfo,
} from 'Api/modules'
import { mapGetters } from 'vuex'
import { USER_TYPE } from 'Configs/user'
export default {
  components: { jujeSnsPopup },
  props: {},
  data() {
    return {
      OPTION_GENDER,
      genderCode: OPTION_GENDER[0].value,
      wordCount: 0,
      jejuEventTerms: Object.freeze(jejuEvent),
      privacyTerms: Object.freeze(privacy),
      isOpenShow: false,
      check: {
        checkOpen: false,
      },
      address: '',
      addressDetail: '',
      zipcode: '',
      photoFileName: '',
      //data
      userNm: '', //이름
      gender: '', //성별
      phone: '', //연락처
      emergencyContact: '', //비상 연락망
      relation: '', //비상 연락망 관계
      birthday: '', //생일
      email: '', //이메일
      photo: '', //사진
      schoolName: '', //학교명
      schoolGrade: '', //학년
      instagram: '', //SNS 인스타그램
      facebook: '', //SNS 페이스북
      blog: '', //SNS 블로그
      youtube: '', //SNS 유튜브
      arebeitYn: '', //알바 유무
      uniqueness: '', //건강 및 특이사항
      privacyYn: '', //개인정보 동의 여부
      region: '', //신청지역
      period: '', //신청차수
      order_fullText:
        '전체 &#58; 1차 &#47; 2차 중복 참여 2022&#46;06&#46;26&#40;일&#41; &#45; 2022&#46;08&#46;21&#40;일&#41;',
      order_1text:
        '1차 &#58; 2022&#46;06&#46;26&#40;일&#41; &#45; 2022&#46;07&#46;25&#40;월&#41;',
      order_2text:
        '2차 &#58; 2022&#46;07&#46;24&#40;일&#41; &#45; 2022&#46;08&#46;21&#40;일&#41;',
      inputPhoto: null,
      motive: null,
      eventSeq: 2,
      eventId: 0,
      isApplicationForm: false,
      snsPoints: false,
    }
  },
  watch: {
    motive(newVal) {
      this.wordCount = this.byteLength(newVal)
    },
    eventId() {
      if (this.eventId == 0) {
        this.isApplicationForm = false
      } else {
        this.isApplicationForm = true
      }
    },
    snsPoints() {
      if (this.snsPoints === true) {
        window
          .$('.__login, .container,.wrapper.main')
          .css('position', 'inherit')
        window.$('.mobileTopMenu').css('z-index', 'auto')
        window.$('.container').on('scroll touchmove mousewheel', e => {
          e.preventDefault()
        })
      } else {
        window.$('.__login, .container').css('position', 'relative')
        window.$('.wrapper.main').css('position', 'absolute')
        window.$('.mobileTopMenu').css('z-index', '1')
        window.$('.container').unbind()
      }
    },
  },
  methods: {
    endEvent() {
      this.$toasted.error('종료된 차수입니다. 2차를 선택해주세요.')
    },
    snsData: function () {
      this.snsPoints = false
    },
    snsPoint() {
      this.snsPoints = true
    },
    toggleOpen() {
      this.isOpenShow = !this.isOpenShow
    },
    byteLength(str) {
      let count = 0
      let ch = ''
      for (let i = 0; i < str.length; i++) {
        ch = str.charAt(i)
        if (escape(ch).length === 6) {
          count++
        }
        count++
      }
      return count
    },
    validName(data) {
      return /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/g.test(data)
    },
    validNumber(data) {
      return /^[0-9]*$/.test(data)
    },
    validEmail(data) {
      return /[-\d\S.+_]+@[-\d\S.+_]+\.[\S]{2,4}/.test(data)
    },
    validPhone(data) {
      return /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/.test(data)
    },
    validSpecial(data) {
      return /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g.test(
        data,
      )
    },
    chkIsValidInput(saveData) {
      if (this.validSpecial(saveData.motive)) {
        this.$toasted.error('지원동기에는 이모티콘을 사용하실 수 없습니다.')
        this.$refs['motive'].focus()
        return false
      }
      // 폼 데이터 정합성 검사
      if (!this.region) {
        this.$toasted.error('신청지역을 선택해주세요.')
        this.$refs['region'].focus()
        return false
      }
      if (!this.period) {
        this.$toasted.error('신청차수를 선택해주세요.')
        this.$refs['period'].focus()
        return false
      }
      if (!this.validName(saveData.userNm)) {
        this.$toasted.error('이름 형식이 올바르지 않습니다.')
        this.$refs['userNm'].focus()
        return false
      }
      if (!this.gender) {
        this.$toasted.error('성별을 선택하세요.')
        return false
      }
      if (!this.validPhone(saveData.phone)) {
        this.$toasted.error('핸드폰 번호 형식이 올바르지 않습니다.')
        this.$refs['phone'].focus()
        return false
      }
      if (!this.emergencyContact) {
        this.$toasted.error('비상연락망을 입력하세요.')
        this.$refs['emergencyContact'].focus()
        return false
      } else if (!this.validPhone(saveData.emergencyContact)) {
        this.$toasted.error('비상연락망 번호 형식이 올바르지 않습니다.')
        this.$refs['emergencyContact'].focus()
        return false
      }
      if (!this.relation) {
        this.$toasted.error('비상연락망과의 관계 입력하세요.')
        this.$refs['relation'].focus()
        return false
      }
      if (!this.birthday) {
        this.$toasted.error('생년월일을 입력하세요.')
        this.$refs['birthday'].focus()
        return false
      }
      if (!this.email) {
        this.$toasted.error('이메일을 입력하세요.')
        this.$refs['email'].focus()
        return false
      } else if (!this.validEmail(saveData.email)) {
        this.$toasted.error('이메일 형식이 올바르지 않습니다.')
        this.$refs['email'].focus()
        return false
      }
      if (!this.photoFileName) {
        this.$toasted.error('사진을 등록하세요.')
        this.$refs['photoFileName'].focus()
        return false
      }
      if (!this.address) {
        this.$toasted.error('주소를 입력해주세요.')
        this.$refs['address'].focus()
        return false
      }
      if (!this.addressDetail) {
        this.$toasted.error('나머지 주소를 입력해주세요.')
        this.$refs['addressDetail'].focus()
        return false
      }
      if (!this.schoolName) {
        this.$toasted.error('학교를 입력해주세요.')
        this.$refs['schoolName'].focus()
        return false
      }
      if (!this.schoolGrade) {
        this.$toasted.error('학년을 입력해주세요.')
        this.$refs['schoolGrade'].focus()
        return false
      }
      if (!this.uniqueness) {
        this.$toasted.error(
          '건강 및 특이사항을 입력해주세요. 없으면 없음이라 입력해주세요.',
        )
        this.$refs['uniqueness'].focus()
        return false
      }
      if (!this.arebeitYn) {
        this.$toasted.error('경력사항을 선택해주세요.')
        return false
      }
      if (!this.motive) {
        this.$toasted.error('지원동기를 입력해주세요.')
        this.$refs['motive'].focus()
        return false
      }
      if (this.privacyYn == 'N' || this.privacyYn == '') {
        this.$toasted.error('개인 정보 보호 정책에 동의해주세요.')
        // this.$refs['checkOpen'].focus()
        return false
      }

      return true
    },
    findAddress() {
      if (this.isApp) {
        searchAddressGetResult(({ zonecode, address }) => {
          this.zipcode = zonecode
          this.address = address
          this.addressDetail = ''
        })
      } else {
        //카카오 지도 발생
        new window.daum.Postcode({
          oncomplete: data => {
            //선택시 입력값 세팅
            const { zonecode, address } = data
            this.zipcode = zonecode
            this.address = address
            this.addressDetail = ''
            console.error(zonecode, address)
            console.info(this.zipcode)
          },
        }).open()
      }
    },
    onUploadClick() {
      this.$refs['fileInput'].click()
    },
    async onFileChanged(event) {
      // 1M
      var maxFileSize = 1 * 1024 * 1000
      // console.log(file.size)
      const file = event.target.files[0]
      if (file) {
        // 파일 크기 제한
        if (file.size > maxFileSize) {
          this.$toasted.error('사진 크기는 1M이하만 가능합니다.')
          return false
        } else {
          this.$toasted.show('사진이 업로드되었습니다.')
        }

        //허용 파일 타입
        const isAcceptable = [
          'image/jpeg',
          'image/png',
          'image/bmp',
          'image/gif',
        ].includes(file.type)
        if (isAcceptable) {
          // todo:: 파일업로드 api 태우고, 파일값 리턴받음.

          this.photoFileName = file.name

          try {
            const res = await uploadUserImg({ uploadFile: file })
            this.photo = res
            var reader = new FileReader()
            reader.onload = e => {
              this.inputPhoto = e.target.result
            }
            reader.readAsDataURL(file)
            this.isDelete = false
          } catch (e) {
            console.error(e)
            // this.deleteAttachedFile()
            return
          }
        }
        // else {
        //   this.deleteAttachedFile()
        // }
      }
    },
    async fetchPersonalInfo() {
      const res = await getBasicUserInfo()
      this.email = res.email
      this.address = res.address
      this.zipcode = res.zipcode
      this.addressDetail = res.addressDetail
      this.userNm = res.userNm
      this.photo = res.photo
      this.photoFileName = res.photoFileName
      this.phone = res.phone
      this.birthday = res.birthday || ''
      this.gender = res.gender ? res.gender : 'NONE'
    },
    async fetchEventRInfo() {
      const res = await getEventRInfo({
        eventSeq: this.eventSeq,
        userId: this.account.userId,
      })
      if (res) {
        this.eventId = res.eventId
        this.eventSeq = res.eventSeq
        this.userNm = res.userNm
        this.gender = res.gender ? res.gender : 'NONE'
        this.phone = res.phone
        this.emergencyContact = res.emergencyContact
        this.relation = res.relation
        this.birthday = res.birthday || ''
        this.email = res.email
        this.photo = res.photo
        this.photoFileName = res.photoFileName
        this.address = res.address
        this.addressDetail = res.addressDetail
        this.zipcode = res.zipcode
        this.schoolName = res.schoolName
        this.schoolGrade = res.schoolGrade
        this.uniqueness = res.uniqueness
        this.instagram = res.instagram
        this.facebook = res.facebook
        this.blog = res.blog
        this.youtube = res.youtube
        this.arebeitYn = res.arebeitYn
        this.motive = res.motive
        this.privacyYn = res.privacyYn
        ;(this.region =
          res.region == '속초'
            ? 'sokcho'
            : res.region == '여수'
            ? 'yeosu'
            : ''),
          (this.period =
            res.period ==
            '전체 : 1차 / 2차 중복 참여 2022.06.26(일) - 2022.08.21(일)'
              ? 'order_full'
              : res.period == '1차 : 2022.06.26(일) - 2022.07.25(월)'
              ? 'order_1'
              : res.period == '2차 : 2022.07.24(일) - 2022.08.21(일)'
              ? 'order_2'
              : '')
      } else {
        await this.fetchPersonalInfo()
      }
    },
    async save() {
      let saveData = {}
      saveData = {
        eventId: this.eventId,
        eventSeq: this.eventSeq,
        userId: this.account.userId,
        userNm: this.userNm ? this.userNm : '',
        gender: this.gender,
        phone: this.phone ? this.phone : '',
        emergencyContact: this.emergencyContact ? this.emergencyContact : '',
        relation: this.relation ? this.relation : '',
        birthday: this.birthday ? this.birthday : '',
        email: this.email ? this.email : '',
        photo: this.photo ? this.photo : '0',
        address: this.address ? this.address : '',
        addressDetail: this.addressDetail ? this.addressDetail : '',
        zipcode: this.zipcode ? this.zipcode : '',
        schoolName: this.schoolName ? this.schoolName : '',
        schoolGrade: this.schoolGrade ? this.schoolGrade : '',
        uniqueness: this.uniqueness ? this.uniqueness : '',
        instagram: this.instagram ? this.instagram : 'N',
        facebook: this.facebook ? this.facebook : 'N',
        blog: this.blog ? this.blog : 'N',
        youtube: this.youtube ? this.youtube : 'N',
        arebeitYn: this.arebeitYn ? this.arebeitYn : 'N',
        motive: this.motive ? this.motive : '',
        privacyYn: this.privacyYn ? this.privacyYn : 'N',
        region:
          this.region == 'sokcho'
            ? '속초'
            : this.region == 'yeosu'
            ? '여수'
            : '',
        period:
          this.period == 'order_full'
            ? '전체 : 1차 / 2차 중복 참여 2022.06.26(일) - 2022.08.21(일)'
            : this.period == 'order_1'
            ? '1차 : 2022.06.26(일) - 2022.07.25(월)'
            : this.period == 'order_2'
            ? '2차 : 2022.07.24(일) - 2022.08.21(일)'
            : '',
      }
      if (!this.chkIsValidInput(saveData)) return false
      try {
        await putEventRInfo(saveData)
        this.isSaved = true
        this.$toasted.show('신청서 제출이 완료되었습니다.')
        this.isApplicationForm = true
        return true
      } catch (e) {
        console.error(e)
        return false
      }
    },
  },
  computed: {
    ...mapGetters(['accountUserImg']),
    userImg() {
      let photo = ''
      if (this.isDelete) {
        photo =
          this.account.userType === USER_TYPE.EMPLOYEE
            ? require('assets/image/etc/etc-user-noimg.svg')
            : require('assets/image/etc/etc-hotel-noimg.svg')
      } else {
        photo = this.inputPhoto || this.accountUserImg
      }

      return `background: url(${photo}) no-repeat center center;`
    },
  },
  async created() {
    // 이벤트 신청 정보 조회
    console.log(this.account.userId)
    await this.fetchEventRInfo()
  },
}
</script>

<style>
.rightForm li {
  list-style: none;
  width: 20%;
  display: flex;
  width: 12%;
  justify-content: space-between;
  padding-right: 10px;
}
.rightForm {
  display: flex;
  width: 100%;
}
.divisionForm .text {
  width: 50% !important;
}
.divisionForm .t-head.t-sub {
  width: 85px !important;
}
.divisionForm .text.subtext {
  width: 34% !important;
}
.saveCompletion {
  font-weight: 700;
  color: #090909;
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
  width: 100%;
  display: block;
  text-align: center;
  margin-top: 3vw;
  font-size: 12pt;
}
#orderCheckCon {
  display: flex;
  flex-direction: column;
  height: auto;
}
#orderCheckCon div {
  width: 100%;
  display: flex;
  margin: 5px 0;
}
#orderCheckCon div label {
  width: 100%;
}
</style>
