<template>
  <div class="page-item-wrap jejuSns-wrap">
    <div id="jejuSns-img-con">
      <img
        class="close-icon"
        src="~assets/image/ico/close.svg"
        alt="닫기"
        @click="jejuSns()"
      />
      <img
        id="jejuSns-img"
        src="~assets/image/main/jejuSnsEvent.png"
        alt="SNS공유하기"
        usemap="#jejuSns-img"
      />
      <map name="jejuSns-img">
        <area
          alt="카카오톡 공유하기"
          title="카카오톡 공유하기"
          shape="rect"
          coords="130,1352,218,1460"
          style="outline: none; cursor: pointer"
          @click="fn_sendFB('kakao')"
        />
        <area
          alt="페이스북 공유하기"
          title="페이스북 공유하기"
          shape="rect"
          coords="266,1352,352,1460"
          style="outline: none; cursor: pointer"
          @click="fn_sendFB('facebook')"
        />
        <area
          alt="트위터 공유하기"
          title="트위터 공유하기"
          shape="rect"
          coords="407,1352,493,1460"
          style="outline: none; cursor: pointer"
          @click="fn_sendFB('twitter')"
        />
        <area
          alt="라인 공유하기"
          title="라인 공유하기"
          shape="rect"
          coords="550,1352,630,1460"
          style="outline: none; cursor: pointer"
          @click="fn_sendFB('line')"
        />
        <area
          alt="블로그 공유하기"
          title="블로그 공유하기"
          shape="rect"
          coords="130,1494,218,1574"
          style="outline: none; cursor: pointer"
          @click="fn_sendFB('blog')"
        />
        <area
          alt="밴드 공유하기"
          title="밴드 공유하기"
          shape="rect"
          coords="266,1494,352,1574"
          style="outline: none; cursor: pointer"
          @click="fn_sendFB('band')"
        />
        <area
          alt="링크 복사"
          title="링크 복사"
          shape="rect"
          coords="407,1494,493,1574"
          style="outline: none; cursor: pointer"
          @click="copyUrl()"
        />
        <area
          alt="JejuSns"
          title="이미지 다운"
          shape="rect"
          coords="550,1494,630,1574"
          style="outline: none; cursor: pointer"
          href="/img/JejuSns.a12c2e8c.jpg"
          download="JejuSns.jpg"
          target="_self"
        />
      </map>
    </div>
    <input type="text" id="urlInput" class="url-input" />
  </div>
</template>

<script>
import { jejuSnsContents } from 'Configs/terms'
export default {
  props: {},
  components: {},
  data: () => ({
    sendUrl: 'https://www.hogoo153.com/jejuDetail',
    title: '제주 한 달 살기',
    jejuSnsContentsTerms: Object.freeze(jejuSnsContents),
  }),
  created() {
    this.$nextTick(() => {
      window.$('img[usemap]').rwdImageMaps()
    })
  },
  methods: {
    jejuSns() {
      this.$emit('jejuSns')
    },
    copyUrl() {
      //url복사
      const urlInput = document.getElementById('urlInput')
      // urlInput.value = window.document.location.href
      urlInput.value = this.sendUrl
      urlInput.select()
      document.execCommand('copy')
    },

    fn_sendFB(sns) {
      var sendUrl = encodeURIComponent(this.sendUrl)
      // var title = this.title
      var content = this.jejuSnsContentsTerms
      if (sns == 'facebook') {
        window.open('http://www.facebook.com/sharer/sharer.php?u=' + sendUrl)
      } else if (sns == 'twitter') {
        window.open(
          'http://twitter.com/share?url=' +
            sendUrl +
            '&text=' +
            encodeURIComponent(content),
        )
      } else if (sns == 'band') {
        window.open(
          'http://band.us/plugin/share?body=' +
            encodeURIComponent(content) +
            sendUrl,
        )
      } else if (sns == 'blog') {
        window.open(
          'https://share.naver.com/web/shareView.nhn?url=' +
            sendUrl +
            '&title=' +
            this.title,
        )
      } else if (sns == 'line') {
        window.open('http://line.me/R/msg/text/?' + this.title + sendUrl)
      } else if (sns == 'kakao') {
        window.Kakao.Link.sendDefault({
          objectType: 'feed',
          content: {
            title: '호구153 제주 한 달 살기',
            description: '제주도에 꿀알바가 나타났다!',
            imageUrl: 'https://www.hogoo153.com/img/kakaoSharing.c0c97417.jpg',
            imageWidth: 336,
            imageHeight: 280,
            link: {
              mobileWebUrl: 'https://www.hogoo153.com/jejuDetail',
              webUrl: 'https://www.hogoo153.com/jejuDetail',
            },
          },

          buttons: [
            {
              title: '자세히 보기',
              link: {
                mobileWebUrl: 'https://www.hogoo153.com/jejuDetail',
                webUrl: 'https://www.hogoo153.com/jejuDetail',
              },
            },
            // {
            //   title: '앱으로 보기',
            //   link: {
            //     mobileWebUrl: 'https://www.hogoo153.com/jejuDetail',
            //     androidExecutionParams: 'cmp=com.hogoo153_app/.MainActivity',
            //     iosExecutionParams: 'cmp=com.hogoo153_app/.MainActivity',
            //   },
            // },
          ],
        })
      }
    },
  },
  computed: {},
}
</script>

<style>
#urlInput {
  opacity: 0;
}
.jejuSns-wrap {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
  z-index: 9999;
}
#jejuSns-img-con {
  position: relative;
  width: 90%;
  height: 90%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
}
#jejuSns-img-con .close-icon {
  width: 50px;
  position: absolute;
  right: 100px;
  top: 0;
  cursor: pointer;
}
#jejuSns-img-con #jejuSns-img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 95%;
}
@media (max-width: 1024px) {
  #jejuSns-img-con {
    width: 100%;
    height: 95%;
  }
  #jejuSns-img-con .close-icon {
    right: 5vw;
    top: 5vw;
  }
  #jejuSns-img-con #jejuSns-img {
    height: 90%;
    top: auto;
    bottom: 0;
    transform: translate(-50%, 0);
  }
}
</style>
